<template>
  <div>
    <div>
      <h1
        class="header-text pb-3"
        ref="headerEditProfile"
        aria-labelledby="header for edit profile page"
        data-cy="header-admin-reports-pairings"
        autofocus
      >
        Student/Mentor Pairings
      </h1>
      <div class="flex">
        <div class="flex-auto">
          <div class="mb-2">Term</div>
          <select
            class="mb-2 w-1/2 rounded border-dark-gray py-1"
            v-model="term"
          >
            <option
              class="h-8"
              v-for="(term, key) in terms"
              :key="key"
              :value="term"
            >
              {{ term.name }}
            </option>
          </select>
        </div>
        <div class="text-right flex-auto pt-6">
          <Button class="" text="Download Data" @click="downloadData('pairings.csv', records)" />
        </div>
      </div>
    </div>
    <div class="box-style mb-6">
      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div v-else>
        <dynamic-table
          :records="this.records"
          :fields="tableConfig"
          no-data-msg="No pairings to display"
          striped
          hover
        >
        </dynamic-table>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import { get } from "@/composables/httpUtil";
import { downloadData } from "@/composables/downloadUtils";
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/reusable-components/base-level-components/Button";

export default {
  name: "Pairings",
  components: { LoadingSpinner, DynamicTable, Button },
  data() {
    return {
      isLoading: true,
      records: [],
      term: null,
      terms: [],
      tableConfig: [
        {
          display: "Student Last",
          name: "student_last",
          sortable: false,
          class: "",
        },
        {
          display: "Student First",
          name: "student_first",
          sortable: false,
          class: "",
        },
        {
          display: "Student Middle",
          name: "student_middle",
          sortable: false,
          class: "",
        },
        {
          display: "Student Email",
          name: "student_email",
          sortable: false,
          class: "",
        },
        {
          display: "Student Level",
          name: "student_level",
          sortable: false,
          class: "",
        },
        {
          display: "Mentor Prefix",
          name: "mentor_prefix",
          sortable: false,
          class: "",
        },
        {
          display: "Mentor First",
          name: "mentor_first",
          sortable: false,
          class: "",
        },
        {
          display: "Mentor Middle",
          name: "mentor_middle",
          sortable: false,
          class: "",
        },
        {
          display: "Mentor Last",
          name: "mentor_last",
          sortable: false,
          class: "",
        },
        {
          display: "Mentor Suffix",
          name: "mentor_suffix",
          sortable: false,
          class: "",
        },
        {
          display: "Position",
          name: "position",
          sortable: false,
          class: "",
        },
        {
          display: "Email Type",
          name: "email_type",
          sortable: false,
          class: "",
        },
        {
          display: "Primary Email",
          name: "mentor_email",
          sortable: false,
          class: "",
        },
      ],
    };
  },
  mounted() {
    this.fetchLookupResource().then(
      get("admin/terms")
        .then((terms) => {
          this.terms = terms.data;
          this.term = this.lookup_current_term;
        })
        .finally(() => {
          this.$watch(
            (vm) => vm.term,
            () => {
              this.isLoading = true;
              this.getData();
            },
            { immediate: true }
          );
          this.isLoading = false;
        })
    );
  },
  methods: {
    downloadData,
    getData() {
      let url = `admin/reports/pairings?term=${this.term.term_id}`;
      get(url)
        .then((results) => {
          this.records = results;
        })
        .catch((error) => {
          console.log("error in catch statement", error);
          this.records = [];
        })
        .finally(() => (this.isLoading = false));
    },
    ...mapActions(["fetchLookupResource"]),
  },
  computed: {
    ...mapGetters(["lookup_current_term"]),
  },
};
</script>

<style scoped></style>
